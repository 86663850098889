<template>
    <div class="col-12 text-center">
        <div v-if="notEnoughData">
            <!--Error-->
            <error></error>
            <p v-if="notEnoughData" class="alert alert-danger mt-5 text-center">{{$t('not-enough-data')}}</p>
        </div>
        <template v-else>
            <redirect-deposit 
                :playerCurrency="playerCurrency"
                :amount="amount"
                :processorName="processorName"
                :processorType="processorType"
                :walletType="walletType"
                :customerId="customerId"
                :ipAddress="ipAddress"
                :language="language"
                :brandUrl="brandUrl"
                :playerCurrencySymbol="playerCurrencySymbol"
                :minDeposit="minDeposit"
                :maxDeposit="maxDeposit">
            </redirect-deposit>
        </template>
    </div>
</template>

<script>
import store from '../../../store/shared_state';

export default {
    name: 'MandatoDeposit',
    data() {
        return {
            notEnoughData: null,
            playerCurrency: null,
            amount: null,
            processorName: null,
            processorType: null,
            walletType: null,
            customerId: null,
            ipAddress: null,
            language: null,
            brandUrl: null,
            playerCurrencySymbol: null,
            minDeposit: null,
            maxDeposit: null,
        }
    },
    computed: {
        tokenData() { return store.state.tokenData },
    },
    components: {
        Error: () => import('../../others/Error'),
        RedirectDeposit: () => import('../../others/RedirectDeposit.vue'),
    },
    mounted() {
        const tokenData = this.tokenData;

        console.log('-----STORE DATA-----')
        console.log(tokenData)

        if(tokenData && tokenData.currencyCode && tokenData.amount && tokenData.processorName && tokenData.walletType && tokenData.customerId && tokenData.ipAddress && 
          tokenData.extra && tokenData.extra.brandUrl && tokenData.extra.currencySymbol && tokenData.extra.min && tokenData.extra.max) {
            this.playerCurrency = tokenData.currencyCode;
            this.amount = tokenData.amount;
            this.processorName = tokenData.processorName;
            this.processorType = 'EWALLET';
            this.walletType = tokenData.walletType;
            this.customerId = tokenData.customerId;
            this.ipAddress = tokenData.ipAddress;
            this.language = tokenData.extra.languageCode ? tokenData.extra.languageCode : 'en';
            this.brandUrl = tokenData.extra.brandUrl;
            this.playerCurrencySymbol = tokenData.extra.currencySymbol;
            this.minDeposit = tokenData.extra.min.toString();
            this.maxDeposit = tokenData.extra.max.toString();
        } else {
            this.notEnoughData = true;
        }
    },
}
</script>